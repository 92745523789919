<template>
    <div class="login">
        <el-row type="flex" justify="center" align="middle" class="login-wrapper">
            <el-col :xs="20" :sm="9" class="wrapper" style="min-width: 250px; max-width: 400px;">
                <div class="top">
                  <div class="logo-wrap"> 
                    <img class="logo" v-if="this.companyInfo && this.companyInfo['back-logo']" :src="imgUrl+this.companyInfo['back-logo'].text" />  
                  </div>
                  <h1 style="color: #515C69; font-size:2rem; background: none;">{{msg('back-login-title')}}</h1>
                </div>
                <div class="wrap">
                    <div class="input-wrap">
                        <el-input :placeholder="msg('请输入用户名')" v-on:keyup.enter.native="focusPwd" prefix-icon="el-icon-s-custom" v-model="username" style="margin-bottom: 10px;"></el-input>
                        <el-input type="password" :placeholder="msg('请输入密码')" ref="pwdRef" @keyup.enter.native="focusCode"  prefix-icon="el-icon-key" v-model="password" style="margin-bottom:10px;" show-password></el-input>
                        <div style="margin-bottom:20px; display:flex;" >
                           <el-input :placeholder="msg('验证码')" ref="codeRef" @keyup.enter.native="login()" prefix-icon="el-icon-picture-outline-round" v-model="code" style="display: flex;">
                          </el-input>
                          <img :src="codeUrl" @click="changeCode()" style="min-width:100px; height:40px;"/>
                        </div>
                    </div>
                    <div class="btn-wrap" style="text-align:center;">
                        <el-button type="primary" style="width:200px;" @click="login()">{{msg('登录')}}</el-button> 
                        <el-link style="margin-left:15px;" type="primary" href="reg">{{msg('注册新账号')}}</el-link>
                    </div>
                </div>
            </el-col> 
        </el-row>
        <div style="position: absolute; button: 0px; width:100%; text-align:center;">
            <div style="color: white;">{{msg('')}} </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import kit from "../plugins/kit.js";
import { MessageBox } from 'element-ui';
export default {
  name: 'login',
  mounted:function(){
    
    var company = this.$store.state.companyInfo;
    if(company){
      this.companyInfo = this.companyInfo;
    }else{
      this.axios.get(this.$kit.api.companyInfo).then((result) => {
        //console.log(result); 
        if(result.status){
          this.companyInfo=result.data;
          this.$store.commit('companyInfo', this.companyInfo);
        }
        
      }).catch(function (error) {
        console.error(error);
      });
      
    }

    window.document.title = this.msg("web-title");

  },
  data(){
    return{
      username: "",
      password: "",
      code:"",
      codeUrl: kit.api.code, 
      imgUrl: this.$kit.api.imgView,
      companyInfo: {}
    }
  }, 

  computed:{
      logo: {
          get(){
            if(this.companyInfo['back-logo']){
              return this.imgUrl+this.companyInfo['back-logo'].text;
            }
            return "";
          }
      }, 
  },

  methods:{
    changeCode(){
      this.codeUrl =  kit.api.code+"?"+new Date().getTime();
    },
    focusPwd(){
      this.$refs.pwdRef.focus();
    },
    focusCode(){
      this.$refs.codeRef.focus();
    },

    login(){
      var _this = this;

      if(!this.username){
        this.$message({type: 'info', message: '用户名不能为空' });
        return;
      }

      if(!this.password){
        this.$message({type: 'info', message: '密码不能为空' });
        return;
      }

      if(!this.code){
        this.$message({type: 'info', message: '验证码不能为空'});
        return;
      }

      var data =  {username: this.username, password: this.password, code: this.code};

      this.axios.post(kit.api.login, data).then(function (result) {
        //console.log(result); 
        if(result.status){
          //存数据，
          localStorage.setItem("user", JSON.stringify(result.data));
          
          localStorage.removeItem("locExpWarns");
          localStorage.removeItem("locErrExps");
          localStorage.removeItem("locTimeouts");
          
          _this.$router.push('/'); 

        }else{
          _this.$message({ type: 'warning', message: result.msg || "登录失败" });
        }

        
      }).catch(function (error) {
        console.error(error);
      });
    }
  }

}
</script>

<style scoped lang="scss">

.login{height:100%; width:100%;
  background: url("../assets/img/login-bg.jpg") no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}
.login-wrapper{
  height:100%;
  margin-top:-100px;
}

.wrapper{
  background: white;
  border-radius: 3px;
  //box-shadow: 0px 0px 10px rgba(28, 88, 150,0.4);  
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;  
}

.top{display:flex; align-items: center; }
.logo-wrap{
  width:64px;
  height: 64px;
  overflow:hidden;
}
.logo{
  height:64px;
}

.code-input{
  display:flex;
}

.code-input .el-input__inner{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

@media screen and (max-width: 768px) {
  .login-wrapper{
    margin-top: -30px;
  }
  .login{
    padding-bottom: 30px;
  }
}


</style>
